<template>
  <v-app id="inspire">
    <v-container fluid fill-height>
      <v-layout flex align-center justify-center wrap>
        <v-progress-circular
          v-if="inProgress"
          indeterminate
          color="red"
        ></v-progress-circular>
        <v-flex sm12 md4 v-else>
          <v-card elevation="4" light tag="section">
            <v-card-title>
              <v-layout align-center justify-space-between>
                <h3 class="headline">
                  Клиники Чайка
                </h3>
              </v-layout>
            </v-card-title>
            <v-card-text v-if="error">
              <v-alert
                color="orange"
                type="error"
              >{{ error }}</v-alert>
            </v-card-text>
            <v-card-actions align-center>
              <v-btn
                v-if="!inProgress"
                color="blue-grey"
                class="ma-2 white--text"
                @click="login"
              >
                Войти
                <v-icon
                  right
                  dark
                >
                  mdi-google
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data () {
      return {
        autoLogin: false,
        inProgress: false,
        error: null
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated']),
    },
    async created () {
      if (this.isAuthenticated) {
        await this.$router.replace({ name: 'Dashboard' })
        return
      }

      if (this.$route.query.error) {
        this.error = this.$route.query.error
      }

      if (this.$route.query.autologin === "1") {
        this.inProgress = true

        await this.$store.dispatch('login', {
          redirect: this.$route.query.redirect
        })
      }
    },
    methods: {
      async login () {
        this.inProgress = true

        await this.$store.dispatch('login', {
          redirect: this.$route.query.redirect
        })
      }
    }
  }
</script>
